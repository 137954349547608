import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 45 min. `}<meta itemProp="prepTime" content="PT45M" /></h5>
    <h5>{`Cook time: 90 min. `}<meta itemProp="cookTime" content="PT90M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Large pasta shells, 1 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Olive oil, 2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 4 cloves minced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Red pepper flakes, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Frozen chopped spinach, 20 oz.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Artichoke hearts, 2 14 oz. cans chopped
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Heavy cream, 1 pint</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parmesan, 1 cup grated</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Cheese, 1.5-2 cups shredded (Cabot New York Extra Sharp Cheddar)
        </span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Medium pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Large pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Colanders, 2</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">9x13 glass pan</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Grate parmesan</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate cheese</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Mince garlic</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Roughly chop artichokes</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Bring medium pot of water to boil. Cook pasta until 2 minutes short of al dente. Drain and set aside.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Preheat oven to 400F.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`In heated large pot, cook olive oil, red pepper flakes, and garlic until fragrant, about 1 minute.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add spinach and cook until wilted, about 3 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add artichokes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add cream and bring to simmer over medium-high heat.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Stir in parmesan until melted.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add salt and pepper.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Turn off heat, stir in pasta until well mixed.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Transfer pasta to glass pan.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Sprinkle with cheese.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bake until bubbling, 20-25 minutes. Let rest 10-15 minutes before serving.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      